import * as Sentry from '@sentry/react';
import { camelizeKeys } from 'humps';
import { isStagingOrLocal, getApiUrl } from 'common/utils/urlUtil';
import AlertDialog from 'app/containers/dialogs/AlertDialog';

import { getExtensionContext } from 'chrome-extension/inject/core/lib/utils';
import extensionMessageTypes from 'chrome-extension/background/messageTypes';
import { serialize } from 'app/utils/ApiUtils';
import { isJSONParsingError } from 'app/utils/MiscUtils';
import { openDialog } from 'app/lib/openDialog';
import { getCurrentSite } from 'chrome-extension/inject/core/lib/locationUtils';

import {
  isPlainUnauthorizedErrorMsg,
  sendMessageToExtension,
} from 'chrome-extension/utils/ExtensionUtils';
import { recordApiCall } from 'common/lib/apiCallTracker';
import { RateLimitDialog } from 'app/containers/dialogs/RateLimitDialog';
import { SecurityChallengeDialog } from 'app/containers/dialogs/SecurityChallengeDialog';
import { transformFetchOptionsToRequestInit, type FetchOptions } from 'common/lib/apiUtils';

export type { FetchOptions };

async function fetch<T>(...apiParams: [path: string, options?: FetchOptions]): Promise<T> {
  const [_path, options = {}] = apiParams;
  const { path, options: requestOptions } = transformFetchOptionsToRequestInit(_path, options);

  recordApiCall(path);

  let thePromise: Promise<unknown>;
  if (getExtensionContext() === 'content') {
    if (requestOptions.body && requestOptions.body instanceof FormData) {
      requestOptions.body = await serialize(requestOptions.body);
      requestOptions.serialized = true;
    }
    thePromise = sendMessageToExtension({
      type: extensionMessageTypes.FETCH_IN_BACKGROUND_PAGE,
      payload: {
        url: getApiUrl(path),
        options: {
          ...requestOptions,
          headers: {
            ...(requestOptions.headers ?? {}),
            'client-origin': getCurrentSite(),
          },
        },
      },
      old: true,
    });
  } else {
    thePromise = window.fetch(getApiUrl(path), requestOptions).then((response) => {
      // 201901 Scott: if backend 500's, the response won't be JSON formatted, so return this instead of breaking frontend
      if (response.status >= 500) {
        return { json: { result: response.status }, response };
      }

      if (process.env.IS_DEVELOPMENT) {
        // Used only on local environment when using `npm run proxy:backend`
        const proxyHost = response.headers.get('x-proxy-host');
        if (proxyHost) {
          window.__PROXY_HOST__ = proxyHost;
        }
      }

      // If the response is HTML, return the HTML content
      const contentType = response.headers.get('content-type');
      if (contentType?.includes('text/html')) {
        return response
          .clone()
          .text()
          .then((html) => ({ html, response: response.clone() }))
          .catch((e) => {
            console.error('Failed to parse HTML response', e);
            return Promise.reject(Object.assign(e, { response: response.clone() }));
          });
      }

      return response
        .clone() // Performing response.clone() allows reading json or text responses more than once.
        .json()
        .then((json) => ({ json, response }))
        .catch((e) => {
          if (isJSONParsingError(e)) {
            response
              .clone()
              .text()
              .then((responseText) => {
                console.error('Received non-json response', responseText);
                Sentry.withScope((scope) => {
                  scope.setExtra('responseText', responseText);
                  scope.setTag('path', path);
                  scope.setTag('status', response.status);
                  Sentry.captureException(e);
                });
              });
          }

          return Promise.reject(Object.assign(e, { response: response.clone() }));
        });
    });
  }

  return thePromise.then(({ json, html, response }) => {
    /**
     * If API returns cf-mitigated, inside header and that's set to challenge
     * It means that the request has been flagged, show security challenge in a dialog
     */
    if (
      !process.env.IS_EXTENSION &&
      response.headers?.has('cf-mitigated') &&
      response.headers.get('cf-mitigated') === 'challenge'
    ) {
      return new Promise((resolve, reject) => {
        openDialog(SecurityChallengeDialog, {
          challengedRoute: path,
          onSuccessCallback: (challengeToken?: string) => {
            fetch(path, {
              ...options,
              ...(typeof challengeToken === 'string' ? { challengeToken } : {}),
            })
              .then(resolve) // Resolves after challenge is solved and API is called again
              .catch(reject); // Reject if there is an error after challenge
          },
        });
      });
    }

    /**
     * If API returns 429, without cf-mitigated it means that the request has been rate limited.
     * And response is HTML, show it in a dialog to notify user about the temporary access
     */
    if (response.status === 429 && html?.length) {
      openDialog(RateLimitDialog, { html });
      return { error: 'API being rate limited', result: response.status };
    }

    if (response.status === 422 || response.status === 400) {
      const errorMessage = json.error;

      /*
          / https://apolloio.slack.com/archives/C03Q1KX15NC/p1708924516400939
          / Don't show alert dialog for "Unauthorized" error in extension.
          / Extension Team needs to do a permissions audit first and improve certain workflows
          / before we can re-enable this again.
          */
      const isExtensionUnauthorized =
        process.env.IS_EXTENSION && isPlainUnauthorizedErrorMsg(errorMessage);

      if (!json.skip_alert_dialog && !isExtensionUnauthorized) {
        openDialog(AlertDialog, {
          message: errorMessage,
          showAlertIcon: true,
          title: 'Error',
          renderMessageAsHtml: true,
        });
      }
      return { error: json.error, result: response.status, json };
    } else if (response.status >= 500) {
      if (isStagingOrLocal()) {
        response.text().then((text: string) => {
          const message =
            `Got HTTP 5XX error on ${(
              requestOptions.method ?? 'get'
            ).toUpperCase()} - ${path} \n\n` + text;
          openDialog(AlertDialog, {
            message,
            showAlertIcon: true,
            title: 'Error',
            renderMessageAsHtml: true,
          });
        });
      }

      const error = json?.error ?? json?.message ?? 'Something went wrong';

      return { error, result: response.status, json };
    }

    if (options.skipCamalization) {
      return json;
    } else {
      return camelizeKeys(json);
    }
  });
}

export default { fetch };

import keymirror from 'keymirror';

export const MSG_DATA_SYNC_ENTITIES = 'MSG_DATA_SYNC_ENTITIES';

enum EntityType {
  Labels = 'labels', // List
  EmailerCampaigns = 'emailerCampaigns', // Sequence
  PhoneCallOutcomes = 'phoneCallOutcomes', // Call dispositions
  PhoneCallPurposes = 'phoneCallPurposes',
  Contacts = 'contacts',
  ContactStages = 'contactStages',
  Territories = 'prospectTerritories',
  Users = 'users',
  Teams = 'teams',
  Scoring = 'recommendationConfigs',
}
type EntityData = Record<string, Record<string, unknown>>; // {<entityId>: {<property name>: <property value>}}
export enum DataSyncAction {
  Add = 'add',
  Update = 'update',
  Delete = 'delete',
}

type DataSyncEntitiesAction = {
  entityType: EntityType; // name of entity type/property in the entities object
  action: DataSyncAction;
};

export type DataSyncEntitiesData = DataSyncEntitiesAction & {
  data: EntityData;
};

// These are all the redux actions for "state.entities" that we want to be included for data syncing
export const DATA_SYNC_ENTITIES_TYPE = keymirror({
  CREATE_LABEL_COMPLETED: null,
  UPDATE_LABEL_COMPLETED: null,
  DESTROY_LABEL_COMPLETED: null,
  CREATE_EMAILER_CAMPAIGN_COMPLETED: null,
  UPDATE_EMAILER_CAMPAIGN_COMPLETED: null,
  ARCHIVE_EMAILER_CAMPAIGN_COMPLETED: null,
  CREATE_PHONE_CALL_OUTCOME_COMPLETED: null,
  UPDATE_PHONE_CALL_OUTCOME_COMPLETED: null,
  DESTROY_PHONE_CALL_OUTCOME_COMPLETED: null,
  CREATE_PHONE_CALL_PURPOSE_COMPLETED: null,
  UPDATE_PHONE_CALL_PURPOSE_COMPLETED: null,
  DESTROY_PHONE_CALL_PURPOSE_COMPLETED: null,
  UPDATE_CONTACT_COMPLETED: null,
  CREATE_CONTACT_STAGE_COMPLETED: null,
  UPDATE_CONTACT_STAGE_COMPLETED: null,
  DESTROY_CONTACT_STAGE_COMPLETED: null,
  CREATE_PROSPECT_TERRITORY_COMPLETED: null,
  UPDATE_PROSPECT_TERRITORY_COMPLETED: null,
  DESTROY_PROSPECT_TERRITORY_COMPLETED: null,
  UPDATE_TEAM_ASSISTANT_SETTING_COMPLETED: null,
  SET_PRIMARY_RECOMMENDATION_CONFIG_ACTIVE_COMPLETED: null,
  UPDATE_USER_COMPLETED: null,
  // Workaround - Not actual action name (Used for actions that also update user entity)
  EXTENSION_DATA_SYNCING_UPDATE_USER: null,
  UPDATE_ENTITIES_ATTRIBUTES_BATCHED: null,
});

// This is the configuration for all the redux actions that we're monitoring
// entityType - name of entity type/property in the entities object. "state.entities.<entityType>"
// action - can be 'add' | 'update' | 'delete'
//      add/update - Updates entity record. See ActionTypes.extensionDataSync.DATA_SYNC_UPDATE_ENTITY
//      delete - Deletes entity record. See ActionTypes.extensionDataSync.DATA_SYNC_DELETE_ENTITY
export const DATA_SYNC_ENTITIES_ACTION: Record<string, DataSyncEntitiesAction> = {
  [DATA_SYNC_ENTITIES_TYPE.CREATE_LABEL_COMPLETED]: {
    entityType: EntityType.Labels,
    action: DataSyncAction.Add,
  },
  [DATA_SYNC_ENTITIES_TYPE.UPDATE_LABEL_COMPLETED]: {
    entityType: EntityType.Labels,
    action: DataSyncAction.Update,
  },
  [DATA_SYNC_ENTITIES_TYPE.DESTROY_LABEL_COMPLETED]: {
    entityType: EntityType.Labels,
    action: DataSyncAction.Delete,
  },
  [DATA_SYNC_ENTITIES_TYPE.CREATE_EMAILER_CAMPAIGN_COMPLETED]: {
    entityType: EntityType.EmailerCampaigns,
    action: DataSyncAction.Add,
  },
  [DATA_SYNC_ENTITIES_TYPE.UPDATE_EMAILER_CAMPAIGN_COMPLETED]: {
    entityType: EntityType.EmailerCampaigns,
    action: DataSyncAction.Update,
  },
  [DATA_SYNC_ENTITIES_TYPE.ARCHIVE_EMAILER_CAMPAIGN_COMPLETED]: {
    entityType: EntityType.EmailerCampaigns,
    action: DataSyncAction.Update,
  },
  [DATA_SYNC_ENTITIES_TYPE.CREATE_PHONE_CALL_OUTCOME_COMPLETED]: {
    entityType: EntityType.PhoneCallOutcomes,
    action: DataSyncAction.Add,
  },
  [DATA_SYNC_ENTITIES_TYPE.UPDATE_PHONE_CALL_OUTCOME_COMPLETED]: {
    entityType: EntityType.PhoneCallOutcomes,
    action: DataSyncAction.Update,
  },
  [DATA_SYNC_ENTITIES_TYPE.DESTROY_PHONE_CALL_OUTCOME_COMPLETED]: {
    entityType: EntityType.PhoneCallOutcomes,
    action: DataSyncAction.Delete,
  },
  [DATA_SYNC_ENTITIES_TYPE.CREATE_PHONE_CALL_PURPOSE_COMPLETED]: {
    entityType: EntityType.PhoneCallPurposes,
    action: DataSyncAction.Add,
  },
  [DATA_SYNC_ENTITIES_TYPE.UPDATE_PHONE_CALL_PURPOSE_COMPLETED]: {
    entityType: EntityType.PhoneCallPurposes,
    action: DataSyncAction.Update,
  },
  [DATA_SYNC_ENTITIES_TYPE.DESTROY_PHONE_CALL_PURPOSE_COMPLETED]: {
    entityType: EntityType.PhoneCallPurposes,
    action: DataSyncAction.Delete,
  },
  [DATA_SYNC_ENTITIES_TYPE.UPDATE_CONTACT_COMPLETED]: {
    entityType: EntityType.Contacts,
    action: DataSyncAction.Update,
  },
  [DATA_SYNC_ENTITIES_TYPE.CREATE_CONTACT_STAGE_COMPLETED]: {
    entityType: EntityType.ContactStages,
    action: DataSyncAction.Add,
  },
  [DATA_SYNC_ENTITIES_TYPE.UPDATE_CONTACT_STAGE_COMPLETED]: {
    entityType: EntityType.ContactStages,
    action: DataSyncAction.Update,
  },
  [DATA_SYNC_ENTITIES_TYPE.DESTROY_CONTACT_STAGE_COMPLETED]: {
    entityType: EntityType.ContactStages,
    action: DataSyncAction.Delete,
  },
  [DATA_SYNC_ENTITIES_TYPE.CREATE_PROSPECT_TERRITORY_COMPLETED]: {
    entityType: EntityType.Territories,
    action: DataSyncAction.Add,
  },
  [DATA_SYNC_ENTITIES_TYPE.UPDATE_PROSPECT_TERRITORY_COMPLETED]: {
    entityType: EntityType.Territories,
    action: DataSyncAction.Update,
  },
  [DATA_SYNC_ENTITIES_TYPE.DESTROY_PROSPECT_TERRITORY_COMPLETED]: {
    entityType: EntityType.Territories,
    action: DataSyncAction.Delete,
  },
  [DATA_SYNC_ENTITIES_TYPE.EXTENSION_DATA_SYNCING_UPDATE_USER]: {
    entityType: EntityType.Users,
    action: DataSyncAction.Update,
  },
  [DATA_SYNC_ENTITIES_TYPE.UPDATE_TEAM_ASSISTANT_SETTING_COMPLETED]: {
    entityType: EntityType.Teams,
    action: DataSyncAction.Update,
  },
  [DATA_SYNC_ENTITIES_TYPE.SET_PRIMARY_RECOMMENDATION_CONFIG_ACTIVE_COMPLETED]: {
    entityType: EntityType.Scoring,
    action: DataSyncAction.Update,
  },
  [DATA_SYNC_ENTITIES_TYPE.UPDATE_USER_COMPLETED]: {
    entityType: EntityType.Users,
    action: DataSyncAction.Update,
  },
  [DATA_SYNC_ENTITIES_TYPE.UPDATE_ENTITIES_ATTRIBUTES_BATCHED]: {
    entityType: EntityType.Contacts,
    action: DataSyncAction.Update,
  },
};

export const generateEntitiesDataSyncPayload = (
  dataSyncActionType: string,
  payload: unknown,
): DataSyncEntitiesData | null => {
  const dataSync = DATA_SYNC_ENTITIES_ACTION[dataSyncActionType];

  if (!(dataSyncActionType in DATA_SYNC_ENTITIES_ACTION)) {
    return null;
  }

  // It should work fine by default if the action.payload has entities.<entityType> (This is the common structure of our payload for entities)
  // In case action.payload does not have the entities.<entityType> structure, we can add a switch case here and handle it properly
  //
  // For those with delete action, entity should have a 'delete' property with value equals to true (This is the common structure for deleted entities)
  // Eg. entities.<entityType>.<entityId>.deleted = true
  switch (dataSyncActionType) {
    default: {
      const entityPayload: DataSyncEntitiesData = {
        entityType: dataSync.entityType,
        action: dataSync.action,
        data: {},
      };

      if (payload != null && typeof payload === 'object' && 'entities' in payload) {
        // @ts-expect-error
        entityPayload.data = payload.entities[dataSync.entityType] ?? {};
      }

      return entityPayload;
    }
  }
};

export const ACTION_WITH_USER_UDPATE = [
  DATA_SYNC_ENTITIES_TYPE.CREATE_PROSPECT_TERRITORY_COMPLETED,
  DATA_SYNC_ENTITIES_TYPE.UPDATE_PROSPECT_TERRITORY_COMPLETED,
  DATA_SYNC_ENTITIES_TYPE.DESTROY_PROSPECT_TERRITORY_COMPLETED,
];

import { type ComponentProps, forwardRef, type ReactNode, isValidElement } from 'react';
import classNames from 'classnames';

// Components
import Icon from 'common/components/design-system/icon/Icon';
import { UnstyledButton } from 'common/components/design-system/core/button/UnstyledButton';
import { BadgeDot } from 'common/components/design-system/badge/BadgeDot';
import { TooltipRenderer } from 'common/components/design-system/tooltip/TooltipRenderer';

// Types
import type { AllIcons } from '$types/icon';

// Styles
import styles from './IconButton.module.scss';

type CTAProps = {
  /**
   * Visual variant of CTA
   */
  variant?: 'default' | 'reversed' | 'ai' | 'unstyled';

  /**
   * Size of CTA
   *
   * - `xxxsmall` (12px)
   * - `xxsmall` (16px)
   * - `xsmall` (20px)
   * - `small` (32px)
   * - `medium` (40px)
   */
  size?: 'xxxsmall' | 'xxsmall' | 'xsmall' | 'small' | 'medium';
  /**
   * The icon to be displayed in the button.
   */
  icon: AllIcons;
  /**
   * The tooltip to be displayed when hovering over the button.
   */
  tooltip: ComponentProps<typeof TooltipRenderer>['tooltip'];
  /**
   * The trigger for the tooltip to be displayed when hovering over the button.
   */
  tooltipTrigger?: ComponentProps<typeof TooltipRenderer>['trigger'];
  /**
   * Whether the button is selected or not.
   * @default false
   */
  selected?: boolean;
  /**
   * Display the Icon Button with a BadgeDot in the upper right corner.
   */
  badgeDotVariant?: ComponentProps<typeof BadgeDot>['variant'];
} & ComponentProps<typeof UnstyledButton> &
  RequiredAriaAttributes;

type RequiredAriaAttributes =
  | {
      /**
       * Defines a string value that labels the current element.
       */
      'aria-label': string;
    }
  | {
      /**
       * Defines a string value that labels the current element.
       */
      'aria-labelledby': string;
    };
/**
 * The IconButton component is a customizable button that prominently features an icon, used for actions within the user interface. It supports different visual variants and sizes and can be accompanied by a tooltip to provide additional context to the user.
 */
export const IconButton = forwardRef<HTMLButtonElement | HTMLAnchorElement, CTAProps>(
  function IconButton(props, ref) {
    const {
      variant = 'default',
      size = 'medium',
      tooltip,
      tooltipTrigger,
      icon,
      children,
      selected = false,
      badgeDotVariant,
      className,
      ...restProps
    } = props;

    const badgeDotSize = badgeDotVariant && size.includes('xsmall') ? 'xsmall' : undefined;

    return (
      <TooltipRenderer tooltip={tooltip} trigger={tooltipTrigger}>
        <UnstyledButton
          ref={ref}
          className={classNames(styles.iconButton, className)}
          data-icon-button-variant={variant}
          data-icon-button-size={size}
          data-icon-button-selected={selected}
          {...restProps}
        >
          <Icon icon={icon} className={styles.icon} aria-hidden />
          {badgeDotVariant && (
            <BadgeDot
              size={badgeDotSize}
              className={classNames(
                styles.badgeDot,
                badgeDotSize === 'xsmall' && styles.xsmallSize,
              )}
              variant={badgeDotVariant}
            />
          )}
        </UnstyledButton>
      </TooltipRenderer>
    );
  },
);

export function isIconButton(element: ReactNode) {
  // This utility is required because in some cases the comparison fails (minification, forwardRef, etc.)
  return isValidElement(element) && element.type === IconButton;
}

// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zp_y4DHM.zp_U1DbY{display:flex;align-items:center;justify-content:center;-moz-column-gap:6px;column-gap:6px}.zp_y4DHM .zp_N8zom{height:4px;width:14px;padding:0;cursor:pointer;border:none;border-radius:var(--border-radius-16);border-radius:var(--spacing-radius-lg, var(--border-radius-16));background-color:var(--color-interactives-pagination-default)}.zp_y4DHM .zp_N8zom.zp_ymTsJ{outline:none}.zp_y4DHM .zp_N8zom:focus-visible{outline:none}.zp_y4DHM .zp_N8zom[data-focus-visible=true]{outline:none;box-shadow:0px 0px 0px 1px var(--color-interactives-button-focus-ring-focus);border:1px solid var(--color-ui-border-white)}.zp_y4DHM .zp_N8zom:hover{background-color:var(--color-interactives-pagination-hover)}.zp_y4DHM .zp_N8zom[data-pressed=true]{background-color:var(--color-interactives-pagination-pressed)}.zp_y4DHM .zp_N8zom:disabled{background-color:var(--color-base-sand-20);cursor:not-allowed}.zp_y4DHM .zp_N8zom[aria-pressed=true]{width:20px;cursor:auto;background-color:var(--color-interactives-pagination-current)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paginationDots": "zp_y4DHM",
	"group": "zp_U1DbY",
	"dot": "zp_N8zom",
	"focus-visible": "zp_ymTsJ"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zp_mldnC{max-width:100%;padding:0}.zp_mldnC[data-focus-visible]{border-radius:var(--border-radius-8);outline:1px solid var(--color-interactives-field-border-focus);outline-offset:1px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badgeWrapper": "zp_mldnC"
};
export default ___CSS_LOADER_EXPORT___;

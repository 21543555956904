import { type ComponentProps, type PropsWithChildren, type ReactNode, useRef } from 'react';
import * as Ariakit from '@ariakit/react';
import Icon from 'common/components/design-system/icon/Icon';
import { Count } from 'common/components/design-system/count/Count';
import { Badge } from 'common/components/design-system/badge/Badge';
import { extractChildren } from 'common/components/design-system/utils';
import { IconButton } from 'common/components/design-system/core/icon-button/IconButton';
import { TabList } from 'common/components/design-system/core/tabs/Tabs';
import classNames from 'classnames';
import { Tooltip } from 'common/components/design-system/tooltip/Tooltip';

import styles from './ModalHeader.module.scss';

export const ModalHeader = (props: PropsWithChildren) => {
  const { children } = props;
  const { badge, count, icon, text, info, tabs, subText } = extractComponentChildren(children);
  const modalHeaderRef = useRef<HTMLDivElement>(null);

  return (
    <div className={classNames(styles.header, tabs && styles.withTabs)} ref={modalHeaderRef}>
      <div className={styles.mainHeader}>
        <div className={styles.headerTitle}>
          <div className={styles.headerContent}>
            {icon}
            {text}
            {info}
            {count}
            {badge}
          </div>
          <Ariakit.DialogDismiss
            render={
              <IconButton
                className={styles.closeButton}
                size="small"
                icon="close"
                aria-label="Close"
                tooltip={<Tooltip content="Close" appendTo="parent" />}
              />
            }
          />
        </div>
        {subText}
      </div>
      {tabs}
      {tabs && <div className={styles.headerBorder} />}
    </div>
  );
};

ModalHeader.Text = Ariakit.DialogHeading;
ModalHeader.Badge = Badge;
ModalHeader.Count = HeaderCount;
ModalHeader.Icon = HeaderIcon;
ModalHeader.Info = HeaderInfo;
ModalHeader.Subtext = HeaderSubtext;
ModalHeader.Tabs = TabList;

function extractComponentChildren(children: ReactNode) {
  return extractChildren(children, {
    text: ModalHeader.Text,
    badge: ModalHeader.Badge,
    count: ModalHeader.Count,
    icon: ModalHeader.Icon,
    info: ModalHeader.Info,
    tabs: ModalHeader.Tabs,
    subText: ModalHeader.Subtext,
  });
}

function HeaderIcon(props: Omit<ComponentProps<typeof Icon>, 'size'>) {
  return <Icon className={styles.headerIcon} {...props} size="medium" />;
}

function HeaderCount(props: Omit<ComponentProps<typeof Count>, 'size'>) {
  return <Count {...props} size="medium" />;
}

function HeaderInfo({ children }: PropsWithChildren) {
  return (
    <IconButton
      icon="info-circle"
      aria-label="Info"
      size="xsmall"
      tooltip={<Tooltip content={children} appendTo="parent" />}
    />
  );
}

function HeaderSubtext(props: ComponentProps<typeof Ariakit.DialogDescription>) {
  return <Ariakit.DialogDescription className={styles.subtext} {...props} />;
}

// eslint-disable-next-line leadgenie/no-import-extensions
import type { Plugin } from 'tippy.js';

export const hideOnScroll: Plugin = {
  fn(instance) {
    let scrollParent: Element | Window | null = null;

    function onScroll() {
      instance.hide();
    }

    return {
      onShow() {
        if (instance.reference) {
          scrollParent = getClosestScrollableParent(instance.reference);
          scrollParent?.addEventListener('scroll', onScroll, { passive: true });
        }
      },
      onHide() {
        if (scrollParent) {
          scrollParent.removeEventListener('scroll', onScroll);
          scrollParent = null;
        }
      },
    };
  },
};

function getClosestScrollableParent(element: Element): Element | Window {
  let parent = element.parentElement;
  while (parent) {
    const { overflowX, overflowY } = getComputedStyle(parent);
    // Check if either overflowX or overflowY of the parent is auto or scroll
    if (/(auto|scroll)/.test(overflowX + overflowY)) {
      return parent;
    }
    parent = parent.parentElement;
  }
  return window;
}

import { v4 as uuid } from 'uuid';

import apiClientSW from 'chrome-extension/background/apiClientSW';
import { getCurrentSite } from 'chrome-extension/inject/core/lib/locationUtils';
import { getZpAppUrl } from 'common/utils/urlUtil';
import { fieldsMapper } from 'app/components/fields/view-options/fieldsMapper';

import IFRAME_MESSAGE_TYPE from 'app/containers/shell/iframeMessageType';
import type { AiFieldEnrichmentRequest } from 'app/actions/aiFieldsActions';
import { DATA_SYNC_ENTITIES_TYPE } from 'common/utils/extensionDataSyncUtil';

export const EXTENSION_IDS = Object.freeze({
  development: 'ececkagaccnfmkopaiemklekhoimmgpn',
  production: 'alhgpfoeiimagjlnfekdhkjlkiomcapa',
});

export const EXTENSION_ID =
  process.env.IS_DEVELOPMENT || process.env.IS_TEST
    ? EXTENSION_IDS.development
    : EXTENSION_IDS.production;

type SendMessageToExtensionOptions = {
  throwOnMissingRuntime?: boolean;
};

type SendMessageToExtensionResponseObject = {
  type?: string;
  version?: string;
  granted?: boolean;
  manuallyCreateNewTab?: boolean;
  activeTabId?: number;
  tabId?: number | undefined;
  tabIdToRefreshOnLogin?: number | null;
  injected?: boolean;
  coverageArray?: string[];
  checked?: boolean;
  found?: boolean;
  message?: string;
};

export type SendMessageToExtensionResponse = SendMessageToExtensionResponseObject | undefined;

const IGNORE_NO_RESPONSE_MESSAGES = [
  IFRAME_MESSAGE_TYPE.STATE_CACHING.CLEAR_CACHED_STATE,
  DATA_SYNC_ENTITIES_TYPE.UPDATE_USER_COMPLETED,
];

/**
 * Sends a message to the Chrome Extension
 * @param {Object} message
 *
 */
export function sendMessageToExtension(
  message: {
    type: string;
    [key: string]: unknown;
  },
  options?: SendMessageToExtensionOptions,
  callback?: (
    result: SendMessageToExtensionResponse,
    resolve: (
      value: SendMessageToExtensionResponse | PromiseLike<SendMessageToExtensionResponse>,
    ) => void,
    reject: (...args: unknown[]) => void,
  ) => unknown,
): Promise<SendMessageToExtensionResponse> {
  return new Promise((resolve, reject) => {
    const defaultOptions: Required<SendMessageToExtensionOptions> = {
      throwOnMissingRuntime: true,
    };
    const _options: SendMessageToExtensionOptions = {
      ...defaultOptions,
      ...options,
    };

    if (!window?.chrome?.runtime?.sendMessage) {
      if (_options.throwOnMissingRuntime) {
        if (process.env.IS_DEVELOPMENT || process.env.IS_TEST) {
          reject(
            new Error('Failed to find Chrome runtime, are you running the extension locally?'),
          );
        } else {
          reject(new Error('Failed to find Chrome runtime'));
        }
      } else {
        resolve(undefined);
      }
      return;
    }

    const extensionId = getCurrentSite() === 'zenprospect' ? EXTENSION_ID : chrome.runtime.id;
    chrome.runtime.sendMessage(extensionId, message, (response: SendMessageToExtensionResponse) => {
      if (response) {
        if (callback) {
          callback(response, resolve, reject);
        } else {
          resolve(response);
        }
      } else if (!IGNORE_NO_RESPONSE_MESSAGES.includes(message.type)) {
        reject(new Error(`Failed to get response after sending message ${message.type}`));
      } else {
        resolve(undefined);
      }
    });
  });
}

/**
 * Sends a message to the active tab (content script)
 * @param {Object} message
 * @param {function} [callback]
 */
export function sendMessageToActiveTab(
  message: object,
  callback?: (...args: unknown[]) => unknown,
) {
  chrome.tabs.query({ currentWindow: true, active: true }, (tabs) => {
    const activeTab = tabs[0];
    chrome.tabs.sendMessage(activeTab.id, message, callback);
  });
}

export function activateExtensionWindowMode({ timeoutDuration = 0 } = {}) {
  sendMessageToExtension(
    {
      type: IFRAME_MESSAGE_TYPE.ACTIVATE_EXTENSION,
      timeoutDuration,
    },
    {
      throwOnMissingRuntime: false,
    },
  );
}

/**
 * Called from non-Apollo websites where we inject ExtensionLoginButton or from the extension popup.
 *
 * This is used to store the active tab where we clicked log in from then opens a new Apollo tab.
 * After we confirm that the user is logged in, we finally refresh the activeTabId we stored here.
 */
export function storeActiveTabIdThenLogin() {
  sendMessageToExtension(
    {
      type: IFRAME_MESSAGE_TYPE.EVERYWHERE.GET_ACTIVE_TAB_ID,
    },
    {
      throwOnMissingRuntime: false,
    },
  ).then(({ activeTabId } = {}) => {
    /** Note: chrome.storage.local.set works here because we call this from the content script or the popup */
    chrome.storage.local.set({ tab_id_to_refresh_on_login: activeTabId });
    window.open(getZpAppUrl(), '_blank');
  });
}

/**
 * Called from the Apollo app, this gets tabIdToRefreshOnLogin from the background script
 *  then refreshes the tab and closes the Apollo app if tabIdToRefreshOnLogin exists.
 */
export function refreshExtensionTabOnLogin() {
  sendMessageToExtension(
    {
      type: IFRAME_MESSAGE_TYPE.EVERYWHERE.GET_TAB_ID_TO_REFRESH_ON_LOGIN,
    },
    {
      throwOnMissingRuntime: false,
    },
  ).then(({ tabIdToRefreshOnLogin } = {}) => {
    if (tabIdToRefreshOnLogin) {
      sendMessageToExtension(
        {
          type: IFRAME_MESSAGE_TYPE.REFRESH_TAB,
          tabId: tabIdToRefreshOnLogin,
        },
        {
          throwOnMissingRuntime: false,
        },
      );
      window.close();
    }
  });
}

/**
 * Utility to check if Apollo extension is installed on the user browser
 * by fetching the extension version
 *
 */
export async function isExtensionInstalled(): boolean {
  try {
    await sendMessageToExtension({
      type: IFRAME_MESSAGE_TYPE.APOLLO.EXTENSION_VERSION,
    });
    return true;
  } catch (err) {
    return false;
  }
}

/**
 * Sends message to extension to set user data in local storage
 *
 */
export async function setUserDataInExtension(data = {}): boolean {
  try {
    await sendMessageToExtension({
      type: IFRAME_MESSAGE_TYPE.APOLLO.SET_USER_DATA,
      payload: {
        currentUserId: data.currentUserId || '',
        currentTeamId: data.currentTeamId || '',
      },
    });
    return true;
  } catch (err) {
    return false;
  }
}

export const requestPermissions = (permissionsToRequest) =>
  new Promise((resolve) => {
    if (!permissionsToRequest) {
      resolve(false);

      return;
    }

    chrome.permissions.request(permissionsToRequest, (granted: unknown) => {
      resolve(granted);
    });
  });

export const getCurrentActiveTab = () =>
  new Promise((resolve) => {
    chrome.tabs.query({ currentWindow: true, active: true }, (tabs) => {
      const activeTab = tabs[0];
      resolve(activeTab);
    });
  });

export const closePopUp = () => {
  const pageWindows = chrome.extension.getViews({ type: 'popup' });
  if (pageWindows?.length) {
    pageWindows[0].close();
  }
};

export const openNewTab = (url) => {
  if (!url) {
    return;
  }

  chrome.tabs.create({
    url,
  });
};

export const updateUserData = (userId, dataToUpdate) =>
  new Promise((resolve, reject) => {
    if (!userId || !dataToUpdate) {
      reject(new Error('Missing user id or data to update.'));

      return;
    }

    apiClientSW
      .fetch(`/users/${userId}`, {
        method: 'put',
        data: dataToUpdate,
      })
      .then((result: unknown) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const applyCSS = (customStyles: string, id: string) => {
  if (!customStyles) {
    return;
  }

  const styleElement = document.createElement('style');
  styleElement.innerText = customStyles;

  if (id) {
    styleElement.setAttribute('id', id);
  }

  document.head.appendChild(styleElement);
};

export const syncUserSettings = (type, id, settings) => {
  sendMessageToExtension({
    type: IFRAME_MESSAGE_TYPE.EVERYWHERE.SYNC_USER_SETTINGS,
    payload: {
      settings,
      id,
      type,
    },
  });
};

export const DEFAULT_DATE_FORMAT = 'MM/dd/yyyy';

export const isPlainUnauthorizedErrorMsg = (errorMessage?: string) => {
  const message = errorMessage?.replace?.(/[^A-Za-z]/g, '')?.toLowerCase();

  if (!message) {
    return false;
  }

  return message === 'unauthorized';
};

export const EXTENSION_WATERFALL_COMMON_TEXT =
  'Your account is set up to search multiple sources. This action may use up to';
export const EXTENSION_WATERFALL_MOBILE_TEXT =
  'Your account is set up to search multiple sources. This action may use up to';

export const getDWFPhoneEnrichmentPayload = (contactIds: string[]) => {
  return {
    modelIds: contactIds,
    fieldIds: [fieldsMapper.contact.phone],
    modality: 'contact',
    requestType: 'real_time',
    uiDynamicFieldRequestId: uuid(),
    enrichmentType: 'waterfall',
  } as AiFieldEnrichmentRequest;
};

// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zp_d0CTE{cursor:pointer;font-weight:var(--typeface-base-weight-regular-400);color:var(--color-base-ocean-50);text-decoration:none}.zp_d0CTE:not(.zp_gwTCc):hover,.zp_d0CTE:not(.zp_gwTCc).zp_EKF92{color:var(--color-ui-text-base-links);text-decoration:none}.zp_d0CTE:not(.zp_gwTCc):hover:not(.zp-button) .zp-icon,.zp_d0CTE:not(.zp_gwTCc).zp_EKF92:not(.zp-button) .zp-icon{color:--color-base-ocean-60;color:var(--zp-icon-hover-color, --color-base-ocean-60)}.zp_d0CTE.zp_wKtnu:hover{cursor:not-allowed;color:var(--color-ui-text-base-disabled)}.zp_d0CTE:active,.zp_d0CTE.zp_VfByx{color:var(--color-base-ocean-70)}.zp_d0CTE:active:not(.zp-button) .zp-icon,.zp_d0CTE.zp_VfByx:not(.zp-button) .zp-icon{color:--color-base-ocean-70;color:var(--zp-icon-active-color, --color-base-ocean-70)}.zp_d0CTE.zp_koP17{color:var(--color-base-rust-40)}.zp__hoLw{text-decoration:underline}.zp_Dg_pW{font-family:var(--typeface-base-family-subheadings),var(--system-ui);font-size:var(--typeface-base-size-xs);font-weight:var(--typeface-base-weight-medium-500);line-height:var(--typeface-base-line-height-xs);text-transform:uppercase}.zp_uMWfT{font-family:var(--typeface-base-family-body),var(--system-ui);font-size:var(--typeface-base-size-xs);font-weight:var(--typeface-base-weight-regular-400);line-height:var(--typeface-base-line-height-sm);text-decoration:underline}.zp__hoLw,.zp_Dg_pW,.zp_uMWfT{color:var(--color-ui-text-base-secondary)}.zp__hoLw:hover,.zp__hoLw.zp_EKF92,.zp_Dg_pW:hover,.zp_Dg_pW.zp_EKF92,.zp_uMWfT:hover,.zp_uMWfT.zp_EKF92{color:var(--color-base-ocean-50)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"default": "zp_d0CTE",
	"noHoverStyles": "zp_gwTCc",
	"hover": "zp_EKF92",
	"disabled": "zp_wKtnu",
	"active": "zp_VfByx",
	"red": "zp_koP17",
	"muted": "zp__hoLw",
	"title": "zp_Dg_pW",
	"action": "zp_uMWfT"
};
export default ___CSS_LOADER_EXPORT___;

import qs from 'qs';
import { decamelizeKeys } from 'humps';

import { readCookie } from 'common/utils/urlUtil';
import { dataWithVersionSha, dataWithFullstorySession } from 'common/lib/versionIdentifyUtils';
import { transformAPIRequestDataForGodMode } from './godMode';

export type FetchOptions = Pick<RequestInit, 'credentials' | 'method' | 'priority'> & {
  data?: Record<string, unknown>;
  body?: string | FormData;
  csrfToken?: string;
  skipCamalization?: boolean;
  skipDecamalization?: boolean;
  skipAlertDialog?: boolean;
  abortSignal?: AbortSignal;
  headers?: Record<string, string>;
  challengeToken?: string;
};

export function transformFetchOptionsToRequestInit(
  path: string,
  options: FetchOptions,
): { path: string; options: RequestInit } {
  let { data = {} } = options;
  if (!options.skipDecamalization) {
    data = decamelizeKeys<Record<string, unknown>>(data);
  }

  data = dataWithVersionSha(data);
  data = transformAPIRequestDataForGodMode(data);
  data = dataWithFullstorySession(data);
  const method = options.method ?? 'get';

  // https://app.asana.com/0/467924783835806/1148760341072779
  // force browser to make an api call instead of caching
  data.cacheKey = new Date().getTime();

  const requestOptions: RequestInit & { headers?: Record<string, string> } = {
    credentials: options.credentials ?? 'include',
    method,
    priority: options?.priority ?? 'high',
  };

  if (options.body) {
    requestOptions.body = options.body;
  } else {
    requestOptions.headers = {
      'Content-Type': 'application/json',
    };
    if (method === 'get') {
      const dataQueryString = qs.stringify(data, {
        arrayFormat: 'brackets',
      });
      path += `?${dataQueryString}`;
    } else {
      requestOptions.body = JSON.stringify(data);
    }
  }

  if (!['get', 'head'].includes(method)) {
    const csrfToken = options.csrfToken ?? decodeURIComponent(readCookie('X-CSRF-TOKEN'));
    if (csrfToken && csrfToken !== 'null') {
      if (!requestOptions.headers) {
        requestOptions.headers = {};
      }
      requestOptions.headers['X-CSRF-TOKEN'] = csrfToken;
    }
  }

  if (options.challengeToken) {
    if (!requestOptions.headers) {
      requestOptions.headers = {};
    }
    requestOptions.headers['x-cf-turnstile-response'] = options.challengeToken;
  }

  requestOptions.signal = options.abortSignal;

  return { path, options: requestOptions };
}

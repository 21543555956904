/* eslint-disable import/no-import-module-exports */
// ^ The rule is disabled because we access `module.hot` and ESLint thinks this is a commonJS module.

import React, { Suspense, useEffect } from 'react';
import { createRoot } from 'common/utils/react-dom-compat';
import ResetWrapper from 'common/components/ui/ResetWrapper';
import { isStagingOrLocal, searchParamsToQuery } from 'common/utils/urlUtil';
import {
  createInjectableDom,
  defaultMeetingsWidgetSubmitOptions,
  getFormValuesFromElement,
  getFormValuesFromLeadObject,
  meetingsWidgetSubmitOptions,
  validateEmptyString,
  widgetLoggerMessage,
} from './meetings-widget.utils';
import { initFormEnrichment } from './meetings-widget.form-enrichment.utils';
import { submitInboundRouter, fetchRouterSettings } from './meetings-widget.requests';
import type {
  ApolloMeetingsWindowObjType,
  MeetingsWidgetFormResponseType,
  InitWidgetConfig,
} from './meetings-widget.types';
import { WidgetProvider, useWidget } from './meetings-widget.state';
import WidgetModal from './components/WidgetModal';
import Spinner from 'common/lib/Spinner';
import styles from './index.module.scss';
import type { WidgetDisplayBlockProps } from './components/WidgetDisplayBlock';

const WidgetBooking = React.lazy(() => import('./components/WidgetBookingUI'));
const WidgetDisplayBlock = React.lazy(() => import('./components/WidgetDisplayBlock'));

const currentScript = document.currentScript;
const customDomElementId = 'apollo-meetings-widget';

const ApolloMeetings: ApolloMeetingsWindowObjType = {
  initWidget: (config: InitWidgetConfig) => {
    const {
      appId,
      schedulingLink,
      domElement = createInjectableDom(customDomElementId),
      formEnrichmentElement,
    } = config;

    // runtime checks for validity
    validateEmptyString(appId, 'initWidget: "appId" must be provided.');
    validateEmptyString(schedulingLink, 'initWidget: "schedulingLink" must be provided.');

    if (!(domElement instanceof HTMLElement)) {
      throw new Error('initWidget: "domElement" must be an HTMLElement.');
    }

    if (
      !isStagingOrLocal() &&
      currentScript instanceof HTMLScriptElement &&
      !currentScript.src.startsWith('https://assets.apollo.io')
    ) {
      throw new Error('Loading scripts from domains other than Apollo is not allowed');
    }

    ApolloMeetings.apolloConfig = {
      appId,
      schedulingLink,
      formEnrichmentElement,
    };

    fetchRouterSettings({
      schedulingLink,
      appId,
      callbackOptions: {
        onError: (errorCode) => {
          if (errorCode === 'not_found_router') {
            console.error(
              widgetLoggerMessage("'initWidget': Invalid or inactive router provided."),
            );
          }

          if (errorCode === 'not_found_appId') {
            console.error(widgetLoggerMessage("'initWidget': Invalid appId provided."));
          }

          console.error(
            widgetLoggerMessage(
              "'initWidget': Some error occurred, please verify if appId and schedulingLink are valid and active.",
            ),
          );
        },

        onSuccess: ({ external }) => {
          if (external.form_enrichment_setting.is_enrichment_enabled) {
            initFormEnrichment(external.form_enrichment_setting);
            ApolloMeetings.initFormEnrichment = () =>
              initFormEnrichment(external.form_enrichment_setting);
          }
        },
      },
    });

    const root = createRoot(domElement);
    root.render(
      <ResetWrapper className={styles.resetOverrides}>
        <WidgetProvider>
          <MeetingsApp />
        </WidgetProvider>
      </ResetWrapper>,
    );
  },
  _appConfig: {
    appId: null,
    schedulingLink: null,
    formEnrichmentElement: undefined,
  },
  set apolloConfig(config: (typeof ApolloMeetings)['appConfig']) {
    this._appConfig = config;
  },
  closeWidget: () => {},
  submit: () => {},
  initFormEnrichment: () => {},
};

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    ApolloMeetings?: ApolloMeetingsWindowObjType;
    HubSpotForms?: object;
  }
}

if (typeof window !== 'undefined' && !window.ApolloMeetings) {
  Object.defineProperty(window, 'ApolloMeetings', {
    value: ApolloMeetings,
    configurable: false,
    writable: false,
    enumerable: true,
  });
}

function MeetingsApp() {
  // hooks
  const { state, dispatch } = useWidget();

  // functions
  useEffect(() => {
    ApolloMeetings.closeWidget = () => {
      const domRootElement = document.getElementById(customDomElementId);
      if (domRootElement) {
        domRootElement.style.display = 'none';
      }

      dispatch({ type: 'CLOSE_WIDGET' });
    };

    ApolloMeetings.submit = (providedOptions = {}) => {
      const domRootElement = document.getElementById(customDomElementId);
      if (domRootElement) {
        domRootElement.style.display = 'block';
      }

      const closeOnOutsideClick =
        providedOptions.closeOnOutside ?? providedOptions.closeOnOutsideClick;

      delete providedOptions.closeOnOutside;
      delete providedOptions.closeOnOutsideClick;

      const options = {
        ...defaultMeetingsWidgetSubmitOptions,
        ...(closeOnOutsideClick !== undefined && { closeOnOutsideClick }),
        ...providedOptions,
      };
      Object.assign(meetingsWidgetSubmitOptions, options);

      if (!ApolloMeetings._appConfig.schedulingLink || !ApolloMeetings._appConfig.appId) {
        throw new Error('"schedulingLink" or "appId" missing, check "ApolloMeeting.initWidget"');
      }

      let formResponses: MeetingsWidgetFormResponseType[] = [];
      if (options.map) {
        formResponses = getFormValuesFromElement(options.formId, options.ignoreInvalidValues);
      } else {
        formResponses = getFormValuesFromLeadObject(options.lead, options.ignoreInvalidValues);
      }

      const queryParams = searchParamsToQuery(new URL(window.location.href).searchParams);

      dispatch({ type: 'ROUTER_SUBMIT_START' });

      submitInboundRouter({
        data: {
          schedulingLink: ApolloMeetings._appConfig.schedulingLink,
          queryParams,
          guestDetails: {
            formResponses,
          },
        },
        callbackOptions: {
          onError: (error = {}) => {
            const guestData: WidgetDisplayBlockProps = {
              header: 'Oops! Something went wrong.',
              value:
                'An unexpected error occurred while submitting the form. Please try again after some time.',
            };
            let devError = 'Some error occurred while running router.';

            if (error.type === 'not_found') {
              devError =
                'Invalid "schedulingLink" or "appId" provided. No router found for given link and appId.';
            } else if (error.type === 'rate_limit') {
              guestData.value = 'Max limit reached. Please try again after some time.';
              devError = 'Rate limit exceeded for router submit API.';
            } else if (error.type === 'missing_required_questions') {
              devError = `Missing required questions - ${error.questions}`;
            }

            dispatch({
              type: 'ROUTER_SUBMIT_ERROR',
              payload: {
                data: guestData,
              },
            });

            options.onRouted?.({ success: false, error: devError });
            options.onError?.(devError);
            return;
          },
          onSuccess: (routerRes) => {
            const actionType = routerRes?.matchedAction?.actionType;

            if (actionType === 'custom_message') {
              options.onRouted?.({ success: true, type: 'custom_message' });
              dispatch({
                type: 'ROUTER_SUBMIT_SUCCESS',
                payload: {
                  uiType: 'custom-message',
                  data: {
                    header: routerRes.matchedAction.header,
                    value: routerRes.matchedAction.value,
                  },
                },
              });
              return;
            }

            if (actionType === 'meeting_type') {
              options.onRouted?.({ success: true, type: 'book_meeting' });
              dispatch({
                type: 'ROUTER_SUBMIT_SUCCESS',
                payload: {
                  uiType: 'book-meeting',
                  data: {
                    info: routerRes.matchedAction.meetingTypeSetting,
                    guestDetails: routerRes.guestUserDetails,
                  },
                },
              });
              return;
            }

            if (actionType === 'redirect_to_url') {
              options.onRouted?.({ success: true, type: 'redirect_to_url' });
              dispatch({
                type: 'ROUTER_SUBMIT_SUCCESS',
                payload: {
                  uiType: null,
                },
              });
              ApolloMeetings.closeWidget();

              const redirectUrl = routerRes.matchedAction.value;
              meetingsWidgetSubmitOptions.onRedirect?.(redirectUrl);

              if (!meetingsWidgetSubmitOptions.preventRedirect) {
                window.open(redirectUrl, '_blank');
              }
              return;
            }
          },
        },
      });
    };
  }, [dispatch]);

  // constants
  const { uiType, customMessage, meetingType } = state;

  if (state.routerLoading && !uiType) {
    return (
      <WidgetModal open>
        <div className={styles.spinnerContainer}>
          <Spinner />
        </div>
      </WidgetModal>
    );
  }

  if (!uiType) {
    return null;
  }

  let renderUI = null;

  if (uiType === 'custom-message' || uiType === 'error') {
    renderUI = <WidgetDisplayBlock header={customMessage?.header} value={customMessage?.value} />;
  }

  if (uiType === 'book-meeting' && meetingType) {
    renderUI = <WidgetBooking />;
  }

  return (
    <Suspense
      fallback={
        <WidgetModal open>
          <div className={styles.spinnerContainer}>
            <Spinner />
          </div>
        </WidgetModal>
      }
    >
      {renderUI}
    </Suspense>
  );
}

if (module.hot) {
  module.hot.accept();
}

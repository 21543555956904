const arrowSVG = `<svg width="16" height="6" viewBox="0 0 16 6" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_23)">
<path d="M1.92894 8.51471C1.03803 8.51471 0.591868 7.43757 1.22183 6.8076L7.29289 0.736529C7.68342 0.346004 8.31658 0.346005 8.70711 0.736529L14.7782 6.8076C15.4081 7.43757 14.962 8.51471 14.0711 8.51471H1.92894Z" fill="var(--color-interactives-tooltip-default)"/>
</g>
<defs>
<clipPath id="clip0_1_23">
<rect width="16" height="6" fill="white" transform="matrix(-1 0 0 -1 16 6)"/>
</clipPath>
</defs>
</svg>
`;

export default arrowSVG;

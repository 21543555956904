// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zp_QUSTG{color:var(--color-base-sand-60);font-size:20px;line-height:1}.zp_QUSTG.zp_uUlPi{font-size:10px}.zp_QUSTG.zp_oiZ1b{font-family:var(--typeface-base-family-body),var(--system-ui);font-size:var(--typeface-base-size-xs);font-weight:var(--typeface-base-weight-regular-400);line-height:var(--typeface-base-line-height-sm);line-height:1}.zp_QUSTG.zp_I3jhv{font-family:var(--typeface-base-family-body),var(--system-ui);font-size:var(--typeface-base-size-sm);font-weight:var(--typeface-base-weight-regular-400);line-height:var(--typeface-base-line-height-md);line-height:1}.zp_QUSTG.zp_D0r3Q{font-size:18px}.zp_QUSTG.zp_QwGRF{font-size:30px}.zp_QUSTG.zp_IBLQA{font-size:100px}.zp_Rvwmv{cursor:pointer}.zp_rjKsB:hover{color:var(--color-base-rust-40)}.zp_rjKsB:active{color:var(--color-base-rust-50)}.zp_bKRqw{color:var(--color-ui-text-feedback-caution-light)}.zp_GGyyM{color:var(--color-base-evergreen-50)}.zp_iDrlR{color:var(--color-base-pebble-60)}.zp_HFJ1h{color:var(--color-base-rust-40)}.zp_aXk8E{color:var(--color-base-evergreen-60)}.zp_bJJNR{color:var(--color-base-rust-40)}.zp_c9wmX{color:var(--color-base-ocean-50)}.zp_yJoGS{color:var(--color-base-sand-40)}.zp_Afff5{color:var(--color-base-ember-50)}.zp_VjXx3{color:var(--color-ui-text-feedback-caution-light)}.zp_faOqi{color:var(--color-base-sand-30)}.zp_GDtQM{color:var(--color-base-pebble-50)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"default": "zp_QUSTG",
	"tiny": "zp_uUlPi",
	"small": "zp_oiZ1b",
	"small-medium": "zp_I3jhv",
	"medium": "zp_D0r3Q",
	"medium-large": "zp_QwGRF",
	"large": "zp_IBLQA",
	"clickable": "zp_Rvwmv",
	"color-style-action-danger": "zp_rjKsB",
	"color-style-canvas-dark": "zp_bKRqw",
	"color-style-toast-success": "zp_GGyyM",
	"color-style-toast-warning": "zp_iDrlR",
	"color-style-toast-error": "zp_HFJ1h",
	"color-style-status-success": "zp_aXk8E",
	"color-style-status-error": "zp_bJJNR",
	"color-style-blue": "zp_c9wmX",
	"color-style-light": "zp_yJoGS",
	"color-style-orange": "zp_Afff5",
	"color-style-orange2": "zp_VjXx3",
	"color-style-gray4": "zp_faOqi",
	"color-style-yellow": "zp_GDtQM"
};
export default ___CSS_LOADER_EXPORT___;

export const LATEST_EXTENSION_VERSION = '8.0.0';

// Productivity tools and other websites that we want to ignore when
// looking up accounts in the Chrome extension.
export const DOMAIN_BLACKLIST = {
  'account.box.com': null,
  'affinity.co': null,
  'agilecrm.com': null,
  'amazon.com': null,
  'amazon.co.jp': null,
  'amazon.in': null,
  'amazon.de': null,
  'amazon.co.uk': null,
  'amazon.fr': null,
  'app.asana.com': null,
  'app.hubspot.com': null,
  'app.prosperworks.com': null,
  'apple.com': null,
  'atlassian.net': null,
  'baidu.com': null,
  'bing.com': null,
  'close.io': null,
  'accounts.google.com': null,
  'docs.google.com': null,
  'drive.google.com': null,
  'calendar.google.com': null,
  'dropbox.com': null,
  'dynamics.microsoft.com': null,
  'facebook.com': null,
  'freshworks.com': null,
  'globo.com': null,
  'google.az': null,
  'google.ca': null,
  'google.cn': null,
  'google.co.ao': null,
  'google.co.id': null,
  'google.co.in': null,
  'google.co.jp': null,
  'google.co.kr': null,
  'google.co.th': null,
  'google.co.uk': null,
  'google.co.ve': null,
  'google.co.za': null,
  'google.com.ar': null,
  'google.com.au': null,
  'google.com.br': null,
  'google.com.co': null,
  'google.com.eg': null,
  'google.com.hk': null,
  'google.com.mx': null,
  'google.com.ph': null,
  'google.com.pk': null,
  'google.com.sa': null,
  'google.com.sg': null,
  'google.com.tr': null,
  'google.com.tw': null,
  'google.com.ua': null,
  'google.com.vn': null,
  'google.de': null,
  'google.es': null,
  'google.fr': null,
  'google.gr': null,
  'google.it': null,
  'google.nl': null,
  'google.pl': null,
  'google.ru': null,
  'insightly.com': null,
  'instagram.com': null,
  'linkedin.com': null,
  'live.com': null,
  'admin.mailchimp.com': null,
  'login.mailchimp.com': null,
  'messenger.com': null,
  'msn.com': null,
  'myworkday.com': null,
  'force.com': null,
  'salesforce.com': null,
  'netsuite.com': null,
  'nytimes.com': null,
  'office.com': null,
  'office365.com': null,
  'pipedrive.com': null,
  'propellercrm.com': null,
  'reddit.com': null,
  'sharpspring.com': null,
  'slideshare.net': null,
  'spotify.com': null,
  'streak.com': null,
  'suitecrm.com': null,
  'tellwise.com': null,
  'theguardian.com': null,
  'trello.com': null,
  'twitter.com': null,
  't.co': null,
  'wikipedia.org': null,
  'youtube.com': null,
  'zendesk.com': null,
  'zoho.com': null,
  'zoom.us': null,
};

export const SITE = {
  GOOGLE_CALENDAR: 'google_calendar',
  GMAIL: 'gmail',
  LINKEDIN: 'linkedin',
  SALESFORCE: 'salesforce',
  HUBSPOT: 'hubspot',
  OWLER: 'owler',
  APOLLO: 'zenprospect',
  CHROME: 'chrome',
  APOLLO_EVERYWHERE: 'apollo_everywhere',
};

export const OVERLAY_ACTIVITIES_TAB = 'extensionActivitiesTab';
export const GCAL_ACTIVITIES_TAB = 'extensionActivitiesTabCal';

export type ExtensionUserFlagCounterConfig = { fieldName: string; limit: number };

export const ExtensionUserFlagCounterMapping = {
  SaveCounter: { fieldName: 'chromeExtensionCounterSave', limit: 1 }, // https://apollopde.atlassian.net/browse/EX-2329
  LoginCounter: { fieldName: 'chromeExtensionCounterLogin', limit: 3 }, // https://apollopde.atlassian.net/browse/EX-2329
};

// common communication enum, made for CS <-> BG script communication
export enum CommonCommunicationTypes {
  RecordNewRelicEvent = 'RecordNewRelicEvent',
  SyncCrmConfigState = 'SyncCrmConfigState',
  ClearChromeStorageForTrackingCaching = 'ClearChromeStorageForTrackingCaching',
}

export const GCAL_SIDEBAR_EXPAND_CTA_SOURCE = {
  PRE_MEETING_INSIGHT_CTA: 'pre_meeting_insights_cta',
};

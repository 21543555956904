// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zp_qe0Li{cursor:default;color:var(--color-ui-text-base-primary)}.zp_qe0Li.zp_ARATs:not([data-focus-visible]){outline:none}.zp_qe0Li:focus-visible:not([data-focus-visible]){outline:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "zp_qe0Li",
	"focus-visible": "zp_ARATs"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zp_qKEjW{display:inline-block;position:relative;width:64px;height:64px;transform:scale(1) translate(-50%, -50%);transform:scale(var(--scale, 1)) translate(-50%, -50%)}.zp_qKEjW div{transform-origin:32px 32px}.zp_qKEjW div{animation:loader-ripple-motion 1.2s linear infinite}@keyframes zp_HaRpO{0%{opacity:1}100%{opacity:0}}.zp_qKEjW div::after{content:\" \";display:block;position:absolute;top:3px;left:29px;width:5px;height:14px;border-radius:20%;background:var(--color-base-sand-40);background:var(--color, var(--color-base-sand-40))}.zp_qKEjW div:nth-child(1){transform:rotate(0deg);animation-delay:-1.1s}.zp_qKEjW div:nth-child(2){transform:rotate(30deg);animation-delay:-1s}.zp_qKEjW div:nth-child(3){transform:rotate(60deg);animation-delay:-0.9s}.zp_qKEjW div:nth-child(4){transform:rotate(90deg);animation-delay:-0.8s}.zp_qKEjW div:nth-child(5){transform:rotate(120deg);animation-delay:-0.7s}.zp_qKEjW div:nth-child(6){transform:rotate(150deg);animation-delay:-0.6s}.zp_qKEjW div:nth-child(7){transform:rotate(180deg);animation-delay:-0.5s}.zp_qKEjW div:nth-child(8){transform:rotate(210deg);animation-delay:-0.4s}.zp_qKEjW div:nth-child(9){transform:rotate(240deg);animation-delay:-0.3s}.zp_qKEjW div:nth-child(10){transform:rotate(270deg);animation-delay:-0.2s}.zp_qKEjW div:nth-child(11){transform:rotate(300deg);animation-delay:-0.1s}.zp_qKEjW div:nth-child(12){transform:rotate(330deg);animation-delay:0s}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rippleSpinner": "zp_qKEjW",
	"loader-ripple-motion": "zp_HaRpO"
};
export default ___CSS_LOADER_EXPORT___;

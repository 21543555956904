/* eslint-disable class-methods-use-this -- This rule is opinionated, ignore this rule due to difference of opinion on it's usefulness. */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { useEffect, useState, type ComponentType } from 'react';

const COLOR_SCHEME_PERSIST_KEY = '__experimental_color_scheme';

export const DARK = 'dark';
export const LIGHT = 'light';
export const SYSTEM = 'system';

export type Theme = typeof DARK | typeof LIGHT;
export type ThemePreference = Theme | typeof SYSTEM;

// TODO: Default this to DarkModeManager.preferredColorScheme when dark mode exits beta status
const DEFAULT_THEME = SYSTEM;

class CThemeManager {
  private safeGetLocalStorage(key: string): string | null {
    try {
      return localStorage.getItem(key);
    } catch (e) {
      console.warn('Failed to access localStorage:', e);
      return null;
    }
  }

  private safeSetLocalStorage(key: string, value: string): void {
    try {
      localStorage.setItem(key, value);
    } catch (e) {
      console.warn('Failed to write to localStorage:', e);
    }
  }

  get activeColorScheme(): Theme {
    return document.documentElement.dataset.preferredTheme === DARK ? DARK : LIGHT;
  }

  // if the persisted color scheme is not set, what should be the default?
  get persistedColorScheme(): ThemePreference {
    const persistedColorScheme = this.safeGetLocalStorage(COLOR_SCHEME_PERSIST_KEY);
    const isValidTheme = [DARK, LIGHT, SYSTEM].includes(persistedColorScheme ?? '');
    return isValidTheme ? (persistedColorScheme as ThemePreference) : DEFAULT_THEME;
  }

  get isDarkModeEnabled() {
    return this.activeColorScheme === DARK;
  }

  get isLightModeEnabled() {
    return this.activeColorScheme === LIGHT;
  }

  get preferredColorScheme(): Theme {
    return matchMedia('(prefers-color-scheme: dark)')?.matches ? DARK : LIGHT;
  }

  persistDarkMode() {
    this.safeSetLocalStorage(COLOR_SCHEME_PERSIST_KEY, DARK);
  }

  renderDarkMode() {
    document.documentElement.dataset.preferredTheme = DARK;
  }

  // Set the page to dark mode
  enableDarkMode() {
    this.renderDarkMode();
    this.persistDarkMode();
  }

  persistLightMode() {
    this.safeSetLocalStorage(COLOR_SCHEME_PERSIST_KEY, LIGHT);
  }

  renderLightMode() {
    delete document.documentElement.dataset.preferredTheme;
  }

  // Set the page to light mode
  enableLightMode() {
    this.renderLightMode();
    this.persistLightMode();
  }

  // Set the page to the DEFAULT_THEME.
  // Not currently used.
  clearTheme() {
    try {
      localStorage.removeItem(COLOR_SCHEME_PERSIST_KEY);
    } catch (e) {
      console.warn('Failed to remove from localStorage:', e);
    }
  }

  renderTheme = () => {
    const { activeColorScheme } = this;
    if (activeColorScheme === DARK) {
      this.renderDarkMode();
    } else {
      this.renderLightMode();
    }
  };

  persistSystemTheme() {
    this.safeSetLocalStorage(COLOR_SCHEME_PERSIST_KEY, SYSTEM);
  }

  renderSystemTheme() {
    const systemTheme = this.preferredColorScheme;
    if (systemTheme === DARK) {
      this.renderDarkMode();
    } else {
      this.renderLightMode();
    }
  }

  enableSystemTheme() {
    this.renderSystemTheme();
    this.persistSystemTheme();
  }

  initialize() {
    const persistedTheme = this.persistedColorScheme;

    if (persistedTheme === DARK) {
      this.renderDarkMode();
    } else if (persistedTheme === SYSTEM) {
      this.renderSystemTheme();
    } else {
      this.renderLightMode();
    }

    // Add system theme change listener
    // Not sure if we need this, but thanks to it if user system theme changes and in the app user
    // has selected system preference, the app will update to the new system theme without refreshing the page.
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
      // Only update theme if user has selected system preference
      if (this.persistedColorScheme !== SYSTEM) {
        return;
      }

      if (e?.matches) {
        this.renderDarkMode();
      } else {
        this.renderLightMode();
      }
    });
  }
}

// Extended theme manager that fires events when the persisted theme changes.
class ThemeManagerObserver extends CThemeManager {
  private listeners: Set<() => void> = new Set();

  renderLightMode() {
    super.renderLightMode();
    this.notify();
  }

  renderDarkMode() {
    super.renderDarkMode();
    this.notify();
  }

  renderSystemTheme() {
    super.renderSystemTheme();
    this.notify();
  }

  addListener(listener: () => void) {
    this.listeners.add(listener);
  }

  removeListener(listener: () => void) {
    this.listeners.delete(listener);
  }

  notify() {
    this.listeners.forEach((listener) => listener());
  }
}

// Probably should not be used directly, prefer useTheme
export const ThemeManager = new ThemeManagerObserver();
ThemeManager.initialize();

export const useTheme = () => {
  const [theme, setTheme] = useState<Theme>(ThemeManager.activeColorScheme);

  useEffect(() => {
    const listener = () => {
      setTheme(ThemeManager.activeColorScheme);
    };

    ThemeManager.addListener(listener);

    return () => {
      ThemeManager.removeListener(listener);
    };
  }, [setTheme]);

  return {
    theme,
    themePreference: ThemeManager.persistedColorScheme,
    themeManager: ThemeManager,
    isDarkModeEnabled: theme === 'dark',
    isLightModeEnabled: theme === 'light',
  };
};

type WithThemeProps = { theme: Theme };

// For legacy class components only
export function withTheme<T extends WithThemeProps = WithThemeProps>(
  WrappedComponent: ComponentType<T>,
) {
  const ComponentWithTheme = (props: Omit<T, keyof WithThemeProps>) => {
    const themeProps = useTheme();
    return <WrappedComponent {...themeProps} {...(props as T)} />;
  };

  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
  ComponentWithTheme.displayName = `withTheme(${displayName})`;

  return ComponentWithTheme;
}

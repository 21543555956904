import { getGodEmail } from 'common/lib/godMode';
import type { Team } from '../../types/entities/team';

export function prospectOnLinkedInUrl(accountOrOrganizationIds: unknown[]) {
  //entity_ids[]=123&entity_ids[]=456
  return getApiUrl(
    `/mixed_companies/linkedin_prospect_url?${accountOrOrganizationIds
      .map((id: unknown) => `entity_ids[]=${id}`)
      .join('&')}`,
  );
}

export function isValidHostname(url: string) {
  const HOSTNAME_REGEX =
    /^(?:(?:[A-Za-z0-9]+:)?\/\/)?(?:[A-Za-z0-9](?:[A-Za-z0-9-]{0,}[A-Za-z0-9])?\.){1,4}[A-Za-z0-9][A-Za-z0-9-]{0,23}[A-Za-z0-9]$/;
  return HOSTNAME_REGEX.test(url);
}

export function isValidUrl(url: string | URL) {
  let validurl: URL;

  try {
    validurl = new URL(url);
  } catch (_) {
    return false;
  }
  return validurl.protocol === 'https:';
}

export function extractHost(url: string) {
  let validurl: URL;
  const urlWithProtocol = addProtocolIfMissing(url);
  try {
    validurl = new URL(urlWithProtocol);
  } catch (_) {
    return false;
  }
  return validurl.hostname;
}

export function extractDomain(url?: string) {
  if (!url || url.length === 0) {
    return null;
  }

  try {
    const hostname = new URL(url).hostname;
    const parts = hostname.split('.');

    if (parts.length < 2) {
      return null;
    }

    return `${parts[parts.length - 2]}.${parts[parts.length - 1]}`;
  } catch (error) {
    return null;
  }
}

export function isValidDomain(domain: string) {
  // REGEX explanation https://regex101.com/r/HTr6uU/1
  // https://stackoverflow.com/questions/9238640/how-long-can-a-tld-possibly-be (as of now 23)
  const DOMAIN_REGEX =
    /^(?:[A-Za-z0-9](?:[A-Za-z0-9-]{0,}[A-Za-z0-9])?\.)+[A-Za-z0-9][A-Za-z0-9-]{0,23}[A-Za-z0-9]$/;
  return DOMAIN_REGEX.test(domain);
}

export function getAppBaseUrl() {
  return `https://${process.env.APP_BASE_URL}`;
}

export function getApiAssetsBaseUrl() {
  return 'https://api-assets.apollo.io';
}

export function isStaticDataUrl(url: string) {
  return url.startsWith(getApiAssetsBaseUrl());
}

export function getTailAddressSegment(url: string) {
  const parts = url.split('/').filter((item) => item !== '');
  return parts[parts.length - 1];
}

export function getZpAppUrl(relativeUrl?: string) {
  return `${getAppBaseUrl()}/#${relativeUrl ?? ''}`;
}

export function getApiBaseUrl() {
  return `https://${process.env.APP_BASE_URL}/api/v1`;
}

export function getZpApiUrl(relativeUrl: string | string[]) {
  let baseUrl = `${getApiBaseUrl()}/${relativeUrl}`;
  const godEmail = getGodEmail();
  if (godEmail) {
    if (!relativeUrl.includes('?')) {
      baseUrl = `${baseUrl}?godemail=${godEmail}`;
    } else {
      baseUrl = `${baseUrl}&godemail=${godEmail}`;
    }
  }
  return baseUrl;
}

export function connectToCrmUrl(crm = '', redirect = '', redirectAllCrms = false) {
  if (crm === 'pipedrive') {
    const redirectUrl = redirect ? `&redirect_url=${redirect}` : '';
    return getZpApiUrl(`integration/crm/authorize?crm_name=pipedrive${redirectUrl}`);
  }
  const redirectParam = redirectAllCrms ? `&redirect_url=${redirect}` : '';
  return getZpApiUrl(`${crm}/request_access?surface=app${redirectParam}`);
}

export function unlinkCrmUrl(crm = '', redirectPage = '') {
  if (crm.toLowerCase() === 'pipedrive') {
    return getZpApiUrl('integration/crm/unlink?crm_name=pipedrive');
  }
  if (redirectPage) {
    return getZpApiUrl(`${crm}/unlink_account?redirect_page=${redirectPage}`);
  }
  return getZpApiUrl(`${crm}/unlink_account`);
}

export function getZpWebsite(localPortNumber = '', localProtocol = 'https:') {
  if (process.env.IS_DEVELOPMENT) {
    return `${localProtocol}//localhost:${localPortNumber}`;
  } else if (process.env.IS_STAGING) {
    return 'https://apollo-marketing-site.netlify.app';
  } else {
    return 'https://www.apollo.io';
  }
}

export const publicPath =
  process.env.IS_WEBPACK &&
  (process.env.IS_DEVELOPMENT || (process.env.IS_TEST && !process.env.IS_CI)) &&
  getAppBaseUrl().includes('localhost')
    ? '/public'
    : '';

// // The file must exist under public/images/*
export function imageUrl(imageName: string, full = false) {
  const prefix = full ? getAppBaseUrl() : '';
  return `${prefix}${publicPath}/images/${imageName}`;
}

export const concatPaths = (...args: (string | undefined | null)[]) => {
  const path = args
    .filter((part): part is string => Boolean(part))
    .map((part) => part.replace(/^\/+|\/+$/g, ''))
    .join('/');

  return `/${path}`;
};

export const readCookie = (name: string) => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.startsWith(' ')) {
      c = c.substring(1, c.length);
    }
    if (c.startsWith(nameEQ)) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
};

/**
 * Adds a query parameter to a URL. This may not handle hash properly.
 * @param {string} url
 * @param {string} key
 * @param {*} value
 *
 *
 */
export function addQueryParam(url: string, key: string, value: unknown): string {
  const split = url.split('?');
  const query = split.length >= 2 ? split.splice(-1, 1) : [''];

  const searchParams = new URLSearchParams(query[0]);
  searchParams.set(key, value);

  return split.join('?') + '?' + searchParams.toString();
}

/**
 * Removes a query parameter from a URL. This may not handle hash properly.
 *
 */
export function removeQueryParam(url: string, key: string): string {
  return removeQueryParams(url, [key]);
}

export function readQueryParam(name: string): string | null {
  const params = new URLSearchParams(window.location.hash.split('?')[1]);
  return params.get(name);
}

/**
 * Removes query parameters from a URL. This may not handle hash properly.
 *
 */
export function removeQueryParams(url: string, keys: string[]): string {
  const split = url.split('?');
  const last = split.splice(-1, 1);

  const searchParams = new URLSearchParams(last[0]);

  keys.forEach((k) => searchParams.delete(k));

  return split.join('?') + '?' + searchParams.toString();
}

export function getRedirectToFromHash() {
  // window.location.search does not work for https://localhost:4001/#/join-team?redirectTo=https%3A%2F%2Flocalhost%3A4001%2F%23%2Fpeople%2F5dc614d7d9ced94b3a524970&_k=vlo3iy
  const redirectTo = new URLSearchParams(window.location.hash.split('?')[1]).get('redirectTo');

  return redirectTo;
}

/**
 * This function updates the current url without causing a browser reload or client side navigation
 * @param {*} path Pathname to be updated
 */
export function updateUrlWithoutRefresh(nextPath: string | URL) {
  window.history.pushState(null, null, nextPath);
}

export function getPathFromUrl(url: string) {
  return url.split('?')[0];
}

export function addProtocolIfMissing(url: string) {
  if (!url || /^https?:\/\//.test(url)) {
    // Don't do anything if http or https is present
    return url;
  }

  if (url.startsWith('//')) {
    return 'https:' + url;
  }

  return 'https://' + url;
}

export function isLocal() {
  const { origin } = window.location;
  return origin.includes('localhost:');
}

export function isStagingOrLocal() {
  const { origin } = window.location;
  return origin.includes('.staging-gcp.apollo.io') || origin.includes('localhost:');
}

export function isConversationsEnabled(currentTeam?: Team): boolean {
  return !!currentTeam?.canAccessConversationIntelligenceModule;
}

export function getApiUrl(path: string | string[]) {
  const adjustedPath = path[0] !== '/' ? `/${path}` : path;
  let prefix = '';
  if (process.env.IS_EXTENSION) {
    if (process.env.IS_STAGING || process.env.IS_DEVELOPMENT || process.env.IS_TEST) {
      prefix = `https://${process.env.RAILS_HOST || 'localhost:3001'}/api/v1`;
    } else {
      prefix = process.env.CHROME_EXTENSION_BETA
        ? 'https://beta.apollo.io/api/v1'
        : 'https://extension.apollo.io/api/v1';
    }
  } else {
    prefix = getApiBaseUrl();
  }
  return `${prefix}${adjustedPath}`;
}

/**
 * Replaces entity IDs in the path with `:id`
 * E.g. "contacts/637c8c09573c9d11635dd990" becomes "contacts/:id"
 *
 * @param {string} path
 *
 */
export function replaceIdsWithParameter(path: string): string {
  return path.replace(/\b([0-f]{24})\b/gi, ':id');
}

function isMeetingPath(path: string): path is `/meet/${string}` {
  return path.startsWith('/meet/');
}

function replaceMeetingSlugsWithParameter(path: string): string {
  /**
   * WARNING: EXTREMELY FRAGILE CODE AHEAD.
   *
   * The conditions here are based on the routes declared in the `routes.tsx` file.
   *
   * We need to do this because Datadog SDK does not support React Router v3 and
   * React 17.
   *
   */
  if (!isMeetingPath(path)) {
    return path;
  }

  const rest = path.slice(6);

  // Paths that start with /meet/error do not have parameters.
  if (rest.startsWith('error') || rest.startsWith('inbound-router/error')) {
    return path;
  }

  const segments = rest.split('/');
  if (rest.startsWith('inbound-router')) {
    if (segments[1]) {
      segments[1] = ':schedulingLink';
    }

    return ['/meet', ...segments].join('/');
  }

  if (rest.startsWith('managed-meetings')) {
    if (segments[1]) {
      segments[1] = ':senderMeetingAlias';
    }
    if (segments[2]) {
      segments[2] = ':calendarLink';
    }
    if (segments[3]) {
      segments[3] = ':meetingDuration';
    }

    return ['/meet', ...segments].join('/');
  }

  if (segments[0]) {
    segments[0] = ':calendarLink';
  }

  if (segments[1]) {
    segments[1] = ':meetingDuration';
  }

  return ['/meet', ...segments].join('/');
}

/**
 * Normalizes view names by replacing entity IDs and meeting slugs with parameters
 * @param viewName
 */
export function normalizeViewName(viewName: string): string {
  return replaceMeetingSlugsWithParameter(replaceIdsWithParameter(viewName));
}

/**
 * Returns true if the current page is being loaded in Salesforce iframe mode.
 * The query parameter iframe=sf is set by backend when the page is loaded in Salesforce iframe mode.
 *
 *
 */
export function isSalesforceIframeMode(): boolean {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get('iframe') === 'sf';
}

const UTM_KEYS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_id',
  'utm_term',
  'utm_content',
] as const;

type UtmParamsData = Record<(typeof UTM_KEYS)[number], string> & { referrer: string };

export function utmParamsData(query: Record<string, unknown>): Partial<UtmParamsData> {
  const utmData: Partial<UtmParamsData> = {
    referrer: window.document.referrer,
  };

  UTM_KEYS.forEach((key) => {
    const value = query[key];
    if (value && typeof value === 'string') {
      utmData[key] = value;
    }
  });

  return utmData;
}

export function searchParamsToQuery(searchParams: URLSearchParams) {
  return Object.fromEntries(searchParams.entries());
}

// this is similar to searchParamsToQuery but it converts multiple values to an array
export function searchParamsToQueryMultipleValues(searchParams: URLSearchParams) {
  const queryMap: Record<string, string | string[]> = {};
  // Convert URLSearchParams to {}<string, string[]>
  // `key1=value1&key1=value2&key2=value3` => { key1: [value1, value2], key2: value3 }
  for (const key of searchParams.keys()) {
    const values = searchParams.getAll(key);
    if (values.length === 1) {
      queryMap[key] = values[0] ?? '';
    } else {
      queryMap[key] = values;
    }
  }

  return queryMap;
}

export function getWebsiteTrackingHost() {
  if (isStagingOrLocal()) {
    return 'https://assets.apollo.io/staging/micro/website-tracker/tracker.iife.js';
  }
  return 'https://assets.apollo.io/micro/website-tracker/tracker.iife.js';
}

import React, { useCallback, useEffect } from 'react';
import { Modal, useModal } from 'common/components/design-system/modal/Modal';
import { loadCloudflareTurnstileScript } from 'app/utils/MiscUtils';

declare global {
  interface Window {
    turnstile?: { render: (selector: string, options: Record<string, unknown>) => void };
  }
}

const WIDGET_SITE_KEY = {
  LOGIN: '0x4AAAAAAAks-gkSyQwOYoux',
  SEARCHER: '0x4AAAAAAA2OzLXQA2CoX0m6',
  COMPANY_SEARCHER: '0x4AAAAAABCQWlgJSAIiH5Ue',
  MIXED_PEOPLE_SEARCHER: '0x4AAAAAABCQWlgJSAIiH5Ue', //TODO: Move to separate turnstile widget
  DEFAULT: '0x4AAAAAAA2IlgXDLtmgwdbq',
};

const ROUTE_TO_WIDGET_KEY_MAP: { [key: string]: string } = {
  '/auth/login': WIDGET_SITE_KEY.LOGIN,
  '/mixed_companies/search': WIDGET_SITE_KEY.COMPANY_SEARCHER,
  '/mixed_companies/add_to_my_prospects': WIDGET_SITE_KEY.COMPANY_SEARCHER,
  '/mixed_people/search': WIDGET_SITE_KEY.MIXED_PEOPLE_SEARCHER,
  '/mixed_people/add_to_my_prospects': WIDGET_SITE_KEY.MIXED_PEOPLE_SEARCHER,
};

const MODAL_STYLE: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  color: 'var(--color-base-rust-40)',
};

const getWidgetKeyByRoute = (route: string) => {
  for (const [key, widgetKey] of Object.entries(ROUTE_TO_WIDGET_KEY_MAP)) {
    if (route.startsWith(key)) {
      return widgetKey;
    }
  }
  return WIDGET_SITE_KEY.DEFAULT;
};

export const SecurityChallengeDialog = ({
  challengedRoute,
  onSuccessCallback,
}: {
  challengedRoute: string;
  onSuccessCallback: (challengeToken?: string) => void;
}) => {
  const modal = useModal();
  const widgetSiteKey = getWidgetKeyByRoute(challengedRoute);

  const renderTurnstileWidget = useCallback(async () => {
    await loadCloudflareTurnstileScript();
    if (window?.turnstile) {
      window.turnstile.render('#securityChallenge', {
        sitekey: widgetSiteKey,
        theme: 'light',
        callback(challengeToken: boolean | string) {
          if (challengeToken) {
            onSuccessCallback?.(typeof challengeToken === 'string' ? challengeToken : undefined);
            modal.hide();
          } else {
            console.error('Unable to obtain pre-clearance');
          }
        },
      });
    }
  }, [modal, widgetSiteKey, onSuccessCallback]);

  useEffect(() => {
    modal.show();
    try {
      renderTurnstileWidget();
    } catch (_) {
      console.error('Unable to render Turnstile widget');
    }
  }, [modal, renderTurnstileWidget]);

  return (
    <Modal store={modal} size="small" hideOnInteractOutside={false}>
      <Modal.Content>
        <div style={MODAL_STYLE} id="securityChallenge" />
      </Modal.Content>
    </Modal>
  );
};

export const DYNAMIC_VARIABLE_EMBED_TAG_NAME = 'APDYNAMICVAR';
export const DYNAMIC_VARIABLE_EMBED_BLOT_NAME = 'apdynamicvar-embed';

export enum EDITOR_MODE {
  TEXT = 'text',
  HTML = 'html',
}

export const RICHTEXTEDITOR_CONTEXT_UPDATE_EVENT_NAME = 'richTextEditorContextUpdate';
export const RICH_TEXT_EDITOR_CALLBACK_EVENT_NAME = 'richTextEditorCallback';
export const DVS_SELECTION_EVENT_NAME = 'dvsSelection';

export function applyStyles(selector: string, styles: Partial<CSSStyleDeclaration>) {
  const elements = document.querySelectorAll<HTMLElement>(selector);

  elements.forEach((element) => {
    Object.assign(element.style, styles);
  });
}

export function getStyle(selector: string, ruleName: keyof CSSStyleDeclaration) {
  const element = document.querySelector<HTMLElement>(selector);
  if (element) {
    return getComputedStyle(element)[ruleName];
  }
}

export function getAttribute(selector: string, attributeName: string) {
  return document.querySelector(selector)?.getAttribute(attributeName);
}

export function isHidden(element: HTMLElement) {
  return !(element.offsetWidth || element.offsetHeight || element.getClientRects().length);
}

export function isVisible(element: HTMLElement) {
  return !isHidden(element);
}

export function removeElements(selector: string) {
  document.querySelectorAll(selector).forEach((element) => {
    element.remove();
  });
}

export function appendElement(selector: string, ...elementsToAppend: HTMLElement[]) {
  const element = document.querySelector(selector);

  if (!element) {
    return;
  }

  elementsToAppend.forEach((elementToAppend) => {
    element.appendChild(elementToAppend);
  });
}

export function prependElement(selector: string, ...elementsToPrepend: HTMLElement[]) {
  const element = document.querySelector(selector);

  if (!element) {
    return;
  }

  elementsToPrepend.forEach((elementToPrepend) => {
    element.prepend(elementToPrepend);
  });
}

export function beforeElement(selector: string, ...elementsToAdd: HTMLElement[]) {
  const element = document.querySelector(selector);

  if (!element) {
    return;
  }

  elementsToAdd.forEach((elementToAdd) => {
    element.before(elementToAdd);
  });
}

export function elementExists(selector: string) {
  return !!document.querySelector(selector);
}

export function onDocumentReady(fn: () => void) {
  if (document.readyState !== 'loading') {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
}
